<template lang="pug">
.flex.flex-col.overflow-y-auto.w-full
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{$t("notifications.simpleTransport")}}
  .flex.mt-3.mb-6.relative
    .flex.flex-col
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{$t("notifications.maxSpeed")}}
      .flex.items-center.mt-2
        skif-input-number(
          :disabled='isAllowedCreate && role !== "EDITOR"',
          v-model.number='notification.speed_notmore',
          :error='validate'
        )
        p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{$t("notifications.kmph")}}
    .flex.flex-col.ml-5
      p.text-annotationColor.font-SourceSansPro.text-base.font-semibold {{$t("notifications.allowedDowntime")}}
      .flex.items-center.mt-2
        skif-input-number(
          :disabled='isAllowedCreate && role !== "EDITOR"',
          v-model.number='notification.duration',
          :error='validate'
        )
        p.text-annotationColor.font-SourceSansPro.text-xs.font-semibold.ml-3 {{$t("notifications.min")}}
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='validate') {{$t("notifications.warning")}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['notification', 'isIdleParamsMissing'],
  computed: {
    validate: {
      get() {
        return this.isIdleParamsMissing
      },
      set(newValue) {
        this.$emit('updateIdleParamsMissing', newValue)
      }
    },
    ...mapGetters('login', ['role', 'disabledRole']),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    }
  },
  watch: {
    'notification.duration': function (val) {
      this.validate = val === '' && this.notification.speed_notmore === ''
    },
    'notification.speed_notmore': function (val) {
      this.validate = val === '' && this.notification.duration === ''
    }
  }
}
</script>
